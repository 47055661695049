<template>
  <div class="tw-flex tw-min-h-screen tw-flex-col">
    <NavigationTopBar />

    <div id="content" class="tw-flex-grow">
      <slot />
    </div>

    <NavigationFooterBar />

    <NuxtLoadingIndicator />
  </div>
</template>
